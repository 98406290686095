.quoteForm2 {
  width: 100%;
  background-image: url("../../../assets/images/security5.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 0px 0px;
  height: auto;
  z-index: 200;
}
.quoteformchild h2{
color: #fff;
font-size: var(--fontSize-MediumHeadingMobile);
}

.quoteFormWrapper{
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 31, 65, 0.9);
  filter: brightness(90%) grayscale(10%);
  z-index: 1;
  padding-top: 30px;
  padding-bottom: 30px;

}

.quoteFormWrapper form{
  z-index: 200;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
    border: 5px solid var(--color-secondary);
    padding: 25px 15px;
    border-radius: 20px;
}

.quoteFormWrapper input{
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  margin-left: auto;
  margin-right: auto;
  height: 30px;
  border-radius: 10px;
  border-style: none;
  border-color: var(--color-secondary);
  font-size: 1.5rem;
}

.quoteEnquiry textarea{
  height: 70px;
  width: 100%;
    border-radius: 10px;
  border-style: none;
  border-color: var(--color-secondary);
    margin-top: 10px;
}

.quoteformchild > div{
margin-bottom: 20px;
}

.quoteformchild label{
  font-size: var( --fontSize-paragraphMobile);
  color: #fff;
  text-align: left;
}