.upperMenu {
  background-color: #fff;
  border: 1px solid var(--color-primary);
  width: 100%;
  height: 50px;
}

.upperMenuChild {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  width: 70%;
  margin-left: auto;
  margin-right: auto;
}

.upperMenuLogo {
  /* margin-left: 20px; */
}

.hamburger {
  background-color: var(--color-primary);
  width: 30px;
  height: 30px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 4px;
  align-items: center;
}

.hamburgerLine {
  background-color: #fff;
  width: 18px;
  height: 1.6px;
  border-radius: 10px;
}

.mobileMenu {
  background-color: var(--color-primary);
  color: #fff;
  position: absolute;
  top: 50px;
  height: 100vw;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  text-align: center;
  z-index: 150;
}

.mobileNav {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.mobileSocial {
  text-align: center;
}

.mobileSocial > h3 {
  font-size: 1.8rem;
}

.upperMenuDetails {
  display: none;
}

@media only screen and (min-width: 900px) {
  .upperMenu {
    width: 100%;
    display: block;
    height: 73px;
    background-color: var(--color-primary);
  }

  .upperMenuChild {
    display: flex;
    width: 100%;
  }

  .hamburger {
    display: none;
  }

  .navbarLogo {
  }
  .upperMenuLogo {
    display: none;
  }
  .upperMenuDetails {
    display: flex;
    width: 90%;
    justify-content: space-between;
    align-items: center;
    margin: auto;
  }
  /*Tablets [601px -> 1200px]*/

  .icons {
    font-size: 20px;
    color: var(--color-secondary);
    padding-right: 7.5px;
    padding-left: 7.5px;
  }

  .navBarSocial {
    display: flex;
  }

  .navbarDetails {
    display: flex;
    color: #fff;
    align-items: center;
    font-size: 1.5rem;
    gap: 1.5rem;
  }

  .navbarLeft {
    display: flex;
    align-items: center;
    gap: 2.5rem;
  }
}
