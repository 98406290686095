

.lowerMenuNav ul {
  padding-left: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 7px;
}

.navabar-li-parent li {
  list-style-type: none;
  text-align: center;
}

.nav-link {
  text-decoration: none;
  color: white;
  font-size: 1.8rem;
}

.lowerMenuLogo {
  display: none;
}

.lowerMenuQuote {
  display: none;
}

@media only screen and (min-width:500px){
.navbar {
  display: flex;
  flex-direction: column;
}
}

@media only screen and (min-width: 900px) {
  .navbar {
    display: block;
    position: relative;
    z-index: 100;
    top: 10px;
  }

  .lowerMenuNav {
    height: 60px;
    width: 50%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    /* background-color: #fff; */
    position: absolute;
    top: 20px;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 5px;
  }

  .lowerMenuNav > ul {
    padding-left: auto;
    margin: auto;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 37.5px;
  }

  .lowerMenuNav li {
    list-style-type: none;
    text-align: center;
  }

  .nav-link {
    text-decoration: none;
    color: #fff;
    /* color: var(--color-primary); */
    font-size: 1.5rem;
  }
  .nav-link:hover {
    color: var(--color-secondary);
  }
  .nav-link:active {
    color: var(--color-secondary);
  }
}
