.headerCard {
  display: flex;
  justify-content: center;
  gap: 40px;
  position: relative;
  top: -100px;
  height: 280px;
  color: #fff;
}
.headerCards0 {
  display: none;
}
.headerCardChild {
  padding-top: 30px;
  background-color: var(--color-primary);
  height: 100%;
  width: 28%;
}

.headerCard h3 {
  /* margin-top: 35px; */
  font-size: 2rem;
  text-align: center;
}

.headerCard p {
  /* margin-top: 1.5rem; */
  font-size: 1.5rem;
  width: 80%;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  line-height: 2.55rem;
}

.cardIcon {
  width: 30px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.cardIconImg {
  font-size: 35px;
  text-align: center;
  color: aliceblue;
}
@media only screen and (max-width: 1200px) {
  
  .headerCard {
  gap: 20px;
    height: 300px;
}

  .headerCardChild {
    width: 30%;
  }
}

@media only screen and (max-width: 900px) {
  .headerCard {
    flex-direction: row;
    width: 100%;
    gap: 0px;
    top: -10px;
 
  }

  .headerCardChild {
    padding-top: 45px;
    background-color: var(--color-primary);
    height: 80%;
    width: 100%;
  }
  .headerCards0 {
    background-color: red;
    display: block;
    z-index: 200;
    margin-top: 60px;
  }

  .headerCard h3 {
    /* margin-top: 35px; */
    font-size: 1.7rem;
  }

  .headerCard p {
    /* margin-top: 1.5rem; */
    font-size: 1.2rem;
    width: 80%;
    line-height: 2rem;
  }

  .cardIcon {
    width: 30px;
  }

  .cardIconImg {
    font-size: 20px;
  }
}

@media only screen and (max-width: 470px) {
  .headerCard {
    display: none;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    gap: 0px;
    position: relative;
    top: 130px;
    height: 280px;
    color: #fff;
  }

  .headerCardChild {
    padding-top: 30px;
    background-color: var(--color-primary);
    height: 100%;
    width: 100%;
  }
  .headerCards0 {
    background-color: red;
    height: 100%;
    width: 100vw;
    display: block;
    z-index: 200;
    margin-top: 60px;
  }

  .headerCards2 {
    background-color: var(--color-secondary);
  }
}
