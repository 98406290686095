.faqParent {
  padding: 20px auto;
  height: auto;
}

.faq {
  height: auto;
  display: flex;
  flex-direction: column;
  gap: 2px;
  width: 85%;
  padding-bottom: 20px;
  margin-left: auto;
  margin-right: auto;
}

.faqParent h1 {
  font-size: var(--fontSize-MediumHeadingMobile);
  line-height: var(--lineHeight-Mobile);
  color: var(--color-primary);
  text-align: center;
  margin-bottom: 20px;
  margin-top: 10px;
}

@media only screen and (min-width: 470px) {
  .faqParent h1 {
    font-size: var(--fontSize-MediumHeading);
    line-height: var(--lineHeight-text);
  }

  .faqParent {
    padding-top: 20px;
  }
}

@media only screen and (min-width: 900px) {
  .faqParent h1 {
    font-size: var(--fontSize-MediumHeadingLargeScreen);
  }

  .faqParent {
    padding-top: 20px;
  }

  .faqParent {
    padding-top: 50px;
    padding-bottom: 40px;
    height: auto;
  }
}
