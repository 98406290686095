.belowCard {

  display: none;
  position: relative;
  /* background-color: red; */
  background-color: #fff;
  width: 100%;
  height: 200px;
  top: -144px;
  padding-top: 10px;
   bottom: au0to;
}

.belowCardSpan2 {
  display: none;
}

.belowCard h3 {
  color: var(--color-primary);
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
font-size: var( --fontSize-MediumHeadingMobile);
}

.belowCard p {
  color: #001f41;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

@media only screen and (min-width: 340px) {
  .belowCard {
    top: -150px;
  }
}
/* 
@media only screen and (min-width: 380px) {
  .belowCard {
    top: -185px;
  }
} */

@media only screen and (min-width: 400px) {
  .belowCard {
    top: -20px;
    width: 100%;
    height: auto;
    padding: 10px 10px;
    display: block;
  }

  .belowCardSpan2 {
    display: inline;
  }

  .belowCard h3 {
    width: 80%;
    font-size: 2.2rem;
    line-height: 2.6rem;
margin-top: 2rem;
  
  }

  .belowCard p {
    width: 85%;
    font-size: 1.3rem;
        margin-top: 2rem;
  }
}

@media only screen and (min-width: 650px) {
  .belowCard h3 {
   line-height: 3.4rem;
      font-size: 2.6rem;
  }

    .belowCard p {
    width: 85%;
        font-size: 1.5rem;
  }
}

@media only screen and (min-width: 900px) {
     .belowCard {
    top: -40px;
    padding: 10px 10px;
    bottom: 0;
    margin-bottom: 0;
  }

    .belowCard h3 {
    color: var(--color-accent);
    width: 80%;
    font-size: var( --fontSize-MediumHeadingLargeScreen);
    line-height: 4.9rem;
  }

  .belowCard p {
    color: #001f41;
    width: 70%;
    font-size: 1.5rem;
    line-height: 2rem;
    margin-top: 2rem;
  }
}
